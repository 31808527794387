<template>
  <div class="home">
    <div class="navigation">
      <span @click="gorecommend">系列专题</span>
      <i class="el-icon-arrow-right"></i>

      <span class="nav_info">{{ dataForm.bookName }}</span>
    </div>
    <figure>
      <img :src="dataForm.coverUrl" alt="" />
    </figure>
    <div class="content">
      <section class="ibsn">
        ISBN：{{ dataForm.isbn }} / 出版日期：{{ dataForm.publishTime }}
      </section>
      <section class="title">{{ dataForm.bookName }}</section>

      <section class="synopsis">
         <div class="ql-container ql-snow">
                  <div class="ql-editor">
                      <div v-html="dataForm.contentIntro"></div>
                  </div>
                </div>
       
      </section>
    </div>
    <div class="bottom_relative">
      <div class="relative_top">相关图书</div>
      <div class="buttons" v-if="booksList.length > 5">
        <div
          class="left"
          :class="booksList.length > 5 && page > 1 ? 'active' : ''"
        >
          <i class="el-icon-back" @click="goleft"></i>
        </div>
        <div
          class="right"
          :class="
            booksList.length > 5 && page * 5 < booksList.length ? 'active' : ''
          "
        >
          <i class="el-icon-right" @click="goright"></i>
        </div>
      </div>

      <!-- <ul>
        <li
          v-for="item in booksList"
          @click="relativeBooks(item.id)"
          :key="item.id"
        >
          <figure>
            <img :src="item.coverUrl" alt="" />
          </figure>
        </li>
      </ul> -->
      <div class="swiper-container container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in booksList"
            @click="relativeBooks(item)"
            :key="item.id"
            class="swiper-slide book"
            v-show="show ? index < page * 5 && index >= (page - 1) * 5 : true"
          >
            <div>
              <figure>
                <img
                  :src="booksList[index] && booksList[index].coverUrl"
                  alt=""
                />
              </figure>
              <span  class="text">{{
                booksList[index].bookName
              }}</span>
            </div>
            <!-- <div v-if="!show && index + 1 < booksList.length">
              <figure>
                <img
                  :src="booksList[index] && booksList[index + 1].coverUrl"
                  alt=""
                />
              </figure>
              <span   class="text">{{
                booksList[index+1].bookName
              }}</span>
            </div> -->

            <!-- {{booksList[index].coverUrl }}
            {{booksList[index-1].coverUrl }} -->
          </div>
          <!-- <div class="swiper-slide content">
          <img src="../assets/home/swiper1.png" alt="" />
        </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="buybutton">
      <svg
        t="1685456730217"
        viewBox="0 0 1028 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="8974"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="200.78125"
        height="200"
      >
        <path
          d="M332.8 790.528q19.456 0 36.864 7.168t30.208 19.968 20.48 30.208 7.68 36.864-7.68 36.864-20.48 30.208-30.208 20.48-36.864 7.68q-20.48 0-37.888-7.68t-30.208-20.48-20.48-30.208-7.68-36.864 7.68-36.864 20.48-30.208 30.208-19.968 37.888-7.168zM758.784 792.576q19.456 0 37.376 7.168t30.72 19.968 20.48 30.208 7.68 36.864-7.68 36.864-20.48 30.208-30.72 20.48-37.376 7.68-36.864-7.68-30.208-20.48-20.48-30.208-7.68-36.864 7.68-36.864 20.48-30.208 30.208-19.968 36.864-7.168zM930.816 210.944q28.672 0 44.544 7.68t22.528 18.944 6.144 24.064-3.584 22.016-13.312 37.888-22.016 62.976-23.552 68.096-18.944 53.248q-13.312 40.96-33.28 56.832t-49.664 15.872l-35.84 0-65.536 0-86.016 0-96.256 0-253.952 0 14.336 92.16 517.12 0q49.152 0 49.152 41.984 0 20.48-9.728 35.84t-38.4 14.336l-49.152 0-94.208 0-118.784 0-119.808 0-99.328 0-55.296 0q-20.48 0-34.304-9.216t-23.04-24.064-14.848-32.256-8.704-32.768q-1.024-6.144-5.632-29.696t-11.264-58.88-14.848-78.848-16.384-87.552q-19.456-103.424-44.032-230.4l-76.8 0q-15.36 0-25.6-7.68t-16.896-18.432-9.216-23.04-2.56-22.528q0-20.48 13.824-33.792t37.376-12.288l103.424 0q20.48 0 32.768 6.144t19.456 15.36 10.24 18.944 5.12 16.896q2.048 8.192 4.096 23.04t4.096 30.208q3.072 18.432 6.144 38.912l700.416 0zM892.928 302.08l-641.024-2.048 35.84 185.344 535.552 1.024z"
          p-id="8975"
        ></path>
      </svg>

      点击购买
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "swiper";
export default {
  name: "book",
  data() {
    return {
      dataForm: {},
      activeName: "first",
      booksList: [],
      show: true,
      page: 1,
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      this.show = false;

      setTimeout(() => {
        new Swiper(".container", {
          // loop: true,
          // autoplay:{
          //    delay: 1000
          // },
          // direction: "vertical",
          slidesPerView: 2,
          spaceBetween: 8,
          //  allowSlidePrev: false,
          //   allowSlideNext: false
          // 如果需要分页器

          // 如果需要前进后退按钮

          // 如果需要滚动条
          // scrollbar: '.swiper-scrollbar',
          //如果需要自动切换海报
          // autoplay: {
          //   delay: 1000,//时间 毫秒
          //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
          // },
        });
      }, 1000);
    } else {
      setTimeout(() => {
        new Swiper(".container", {
          // loop: true,
          // autoplay:{
          //    delay: 1000
          // },
          slidesPerView: 5,
          spaceBetween: 20,
          // 如果需要分页器

          // 如果需要前进后退按钮

          // 如果需要滚动条
          // scrollbar: '.swiper-scrollbar',
          //如果需要自动切换海报
          // autoplay: {
          //   delay: 1000,//时间 毫秒
          //   disableOnInteraction: false,//用户操作之后是否停止自动swiper-button-prev轮播默认true
          // },
        });
      }, 500);
    }
  },
  methods: {
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
    buy(url) {
      window.location.href = url;
    },
    gorecommend() {
      this.$router.push({ path: "/recommend", query: { publishRange: 2 } });
    },
    goleft() {
      if (this.booksList.length > 5 && this.page > 1) {
        this.page -= 1;
      }
    },
    goright() {
      if (this.booksList.length > 5 && this.page * 5 < this.booksList.length) {
        this.page += 1;
      }
    },
    relativeBooks(item) {
      // this.$router.push(
      //   {  path: "/recommend",
      //   query: { publishRange: msg }}

      // )bookdetail
      let url = this.$router.resolve({
        path: "/recommend/bookdetail",
        query: { id: item.id, publishRange: item.publishRange },
      });
      // console.log(item,'url');
      window.open(url.href, "_blank");
    },
    getrelative(ids) {
      this.$http
        .get(`/convergencemedia/getBooksInfoByIds`, {
          params: { ids: ids },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // this.dataForm = {

          //   ...res.data,
          //   content:this.decode(res.data.content),
          //   // catalogue:this.decode(res.data.catalogue),
          // }
          this.booksList = res.data.list;
          console.log(res.data, "resdata");
        })
        .catch(() => {});
    },
    getInfo() {
      this.$http
        .get(`/seriessubject/getSeriesSubjectById`, {
          params: { id: this.$route.query.id },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...res.data,
            contentIntro: this.decode(res.data.contentIntro),
            catalogue: this.decode(res.data.catalogue),
          };
          console.log(res.data, "res.data");
          this.getrelative(res.data.relatedRecommendation);
        })
        .catch(() => {});
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.home {
  padding: 0.6rem 1.2rem;
  padding-top: 1.56rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .navigation {
    position: absolute;
    // left: 0.64rem;
    top: 0.7rem;
    span {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      cursor: pointer;
    }
    .nav_info {
      font-size: 0.14rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;
    }
    i {
      color: rgba(178, 1, 3, 1);
      font-size: 0.16rem;
      margin: 0.05rem;
      font-weight: 600;
    }
  }

  //   flex-direction: column;
  figure {
    width: 4.5rem;
    height: 4.5rem;
    background: #ffffff;
    border-radius: 0.02rem;
    border: 0.01rem solid #eeeeee;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    width: 6.68rem;
    .synopsis {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.22rem;
    }
  }
  .bottom_relative {
    position: relative;
    margin-top: 1rem;
    .relative_top {
      font-size: 0.16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #b20103;
      line-height: 0.24rem;
      line-height: 0.24rem;
      padding-bottom: 0.09rem;
      border-bottom: 2px solid #b20103;
      // width: 0.68rem;
      display: inline-block;
      position: relative;
      bottom: -1px;
    }
    .buttons {
      position: absolute;
      right: 0;
      display: flex;
      top: 0;
      i {
        cursor: pointer;
      }

      div {
        margin-left: 0.16rem;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 0.02rem;
        border: 0.01rem solid rgba(0, 0, 0, 0.25);
        color: rgba(0, 0, 0, 0.25);
        line-height: 0.3rem;
        text-align: center;
        font-size: 0.24rem;
        &.active {
          border: 0.01rem solid var(--backgroundColor);
          color: var(--backgroundColor);
        }
      }
    }
    .container {
      border-top: 1px solid #eeeeee;

      width: 12rem;
      .book {
        width: 2.2rem;
        // height: 2.6rem;
        cursor: pointer;
        margin-top: 0.32rem;
        margin-right: 0.25rem;
        figure {
          width: 2.2rem;
          height: 2.2rem;
          background: #ffffff;
          border: 0.01rem solid #eeeeee;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          img {
            height: 90%;
            width: auto;
          }
        }
        .text{
          margin-top: .05rem;
          display: inline-block;
            width: 2.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.14rem;
            color: rgba(0, 0, 0, 0.85);
            line-height: 0.25rem;
        }
      }
    }
    ul {
      border-top: 1px solid #eeeeee;

      width: 12rem;
      display: flex;
      li {
        margin-top: 0.32rem;
        margin-right: 0.25rem;
        figure {
          width: 2.2rem;
          height: 2.2rem;
          background: #ffffff;
          border: 0.01rem solid #eeeeee;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 65%;
          }
        }
      }
    }
  }
  .title {
    font-size: 0.24rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.32rem;
    margin-bottom: 0.24rem;
  }
  .source {
    box-sizing: border-box;
    width: 8.68rem;
    height: 1.08rem;
    background: #f8f8f8;
    border-radius: 0.02rem;
    padding: 0.24rem 0.4rem;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 4.34rem;
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.22rem;
    }
    li:nth-child(2n) {
      width: 3.54rem;
    }
  }
  .price {
    margin-top: 0.25rem;
    margin-bottom: 0.4rem;
    font-size: 0.16rem;
    display: flex;
    line-height: 0.4rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    .buy-button {
      margin-right: 0.23rem;
      width: 1.48rem;
      height: 0.4rem;
      background: var(--backgroundColor);
      border-radius: 0.02rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.4rem;

      font-size: 0.16rem;
      text-align: center;
      cursor: pointer;
    }
  }

  /deep/ .el-tabs__item {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.16rem;
  }
  /deep/ .el-tabs__nav-wrap::after {
    height: 0.01rem;
    background: #e4ccad;
  }
  /deep/ .el-tabs__active-bar {
    background: var(--backgroundColor);
  }
  /deep/ .el-tabs__item.is-active {
    color: var(--backgroundColor);
  }
  /deep/ .el-tabs__content {
    font-size: 0.14rem;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 0.22rem;
  }
  .ibsn {
    display: none;
  }
  .buybutton {
    display: none;
  }
}

@media (max-width: 768px) {
  .home {
    margin: 0;
    flex-wrap: wrap;
    padding: 0;
    .navigation {
      display: none;
    }
    figure {
      height: 3.75rem;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      img {
        height: auto;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse;
      padding: 0.24rem 0.16rem;
      border-top: 0.1rem solid #eeeeee;
      .ibsn {
        display: none;
      }
      //   .title{
      //     display: none;
      //   }
    }
    .price {
      // order: -1;
      margin: 0;
      width: 0.88rem;
      height: 0.36rem;
      font-size: 0.24rem;

      font-weight: 500;
      color: #c3282b;
      line-height: 0.36rem;
      span {
        content: "";
        display: none;
      }
      .buy-button {
        display: none;
      }
    }
    .price::before {
      content: "￥";
      font-size: 0.12rem;

      line-height: 0.44rem;
    }
    .source {
      margin: 0.12rem 0;
      padding: 0;
      display: flex;
      width: auto;
      background: #ffffff;
      height: auto;

      li {
        width: auto;
        padding: 0 0.08rem;
        height: 0.24rem;
        background: #f3e3e3;
        border-radius: 0.02rem;
        text-align: center;
        line-height: 0.24rem;
        margin: 0;
        margin-right: 0.08rem;
      }
      li:nth-child(2n) {
        display: none;
      }
    }
    .title {
      width: 3.43rem;

      font-size: 0.16rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.24rem;
      margin-bottom: 0.24rem;
    }
    .ibsn {
      display: inline;
      padding: 0 0.08rem;

      line-height: 0.24rem;
      font-size: 0.12rem;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);

      background: #eeeeee;
      border-radius: 0.02rem;
      margin-bottom: 0.26rem;
    }
    .synopsis {
      order: -1;
      position: relative;
      //   padding-top: 0.34rem;
      margin-bottom: 0.24rem;
    }
    .synopsis::before {
      content: "";
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.24rem;
      margin-bottom: 0.08rem;
    }
    /deep/ .el-tabs__nav-wrap::after {
      background: #eeeeee;
    }
    .bottom_relative {
      margin-top: 0;
      margin: 0 0.15rem;
      .relative_top {
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.24rem;
        border: none;
      }
      .buttons {
        display: none;
      }
      ul {
        border: none;
        flex-wrap: wrap;
        width: 3.45rem;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 0.24rem;
        li {
          margin: 0;
          figure {
            width: 1.68rem;
            height: 1.68rem;
            background: #ffffff;
            border: 0.01rem solid #eeeeee;
          }
        }
      }
    }
    .bottom_relative {
      .container {
        width: 3.45rem;
        border: none;
        .book {
          width: 100%;
          // height: auto;
          height: 2.22rem !important;
          margin-top: 0;
          margin-bottom: 0.24rem;
          display: flex;
          justify-content: space-between;
          // margin-right: 0.08rem;
          figure {
            width: 1.68rem;
            height: 1.68rem;
            background: #ffffff;
            border: 0.01rem solid #eeeeee;
            box-sizing: border-box;
          }
          .text {
            margin-top: 0.08rem;
            margin-bottom: 0.24rem;
            display: block;
            text-align: center;
            width: 1.68rem;
            overflow: hidden; // 溢出隐藏
            white-space: nowrap; // 强制一行
            text-overflow: ellipsis; // 文字溢出显示省略号
          }
        }
      }
    }
  }

  .buybutton {
    height: 0.56rem;
    width: 100vw;

    background: var(--backgroundColor);
    font-size: 0.16rem;

    font-weight: 400;
    color: #ffffff;
    line-height: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 0.16rem;
      height: 0.16rem;
      fill: #ffffff;
      margin-right: 0.08rem;
    }
  }
}
</style>
